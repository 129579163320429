import { reactive } from "vue";
import axiosInstance from "@/axios/axios.js";
import jwt_decode from "jwt-decode";

export function useMainPage() {
  const state = reactive({
    forms: [
      {
        id: 0,
        action: "login",
      },
      {
        id: 1,
        action: "signUpFirst",
      },
      {
        id: 2,
        action: "signUpSecond",
      },
    ],
    selected: 0,
    loginFormData: {
      login: "",
      password: "",
      errorText: "",
    },
    signupFormData: {
      login: "",
      password: "",
      submitPassword: "",
      selectedRole: 0,
      schoolId: "",
      schoolNameFound: "",
      schoolIdFound: "",
      firstname: "",
      surname: "",
      patronymic: "",
      selectedGender: 0,
      isAccountVerified: false,
      errorText: "",
    },
    isTherePostSignUpCookie: false,
  });

  const setSelectedForm = (selectedId) => {
    state.selected = selectedId;
  };

  const checkNewAccount = async () => {
    return await axiosInstance({
      method: "post",
      url:
        process.env.VUE_APP_SNEDSON_AUTH_SERVER +
        "auth-api/signup/isaccountexist",
      data: {
        username: state.signupFormData.login,
        password: state.signupFormData.password,
      },
    })
      .then((response) => {
        console.log(response);
        return true;
      })
      .catch((response) => {
        console.log(response);
        state.signupFormData.errorText = response.response.data.messageToShow;
        return false;
      });
  };

  const findSchoolById = async () => {
    await axiosInstance({
      method: "get",
      url:
        process.env.VUE_APP_SNEDSON_AUTH_SERVER +
        "auth-api/signup/findschoolbyid",
      params: {
        schoolId: state.signupFormData.schoolId,
      },
    })
      .then((response) => {
        console.log(response);
        state.signupFormData.schoolNameFound = response.data.foundSchoolName;
        state.signupFormData.schoolIdFound = state.signupFormData.schoolId;
      })
      .catch((response) => {
        console.log(response);
        state.signupFormData.errorText = response.response.data.messageToShow;
      });
  };

  const login = async () => {
    await axiosInstance({
      method: "post",
      url: "https://auth.snedson.com/auth-api/login/loginweb",
      data: {
        username: state.loginFormData.login,
        password: state.loginFormData.password,
      },
    })
      .then((response) => {
        console.log(response);
        let snotraRole = response.data.role;
        let snotraDomain = response.data.schoolSnotraDomain;
        let accessToken = response.data.accessToken;
        let refreshToken = response.data.refreshToken;
        window.location.replace(
          "https://" +
            snotraDomain +
            "/post-auth?snotra_role=" +
            snotraRole +
            "&snotra_domain=" +
            snotraDomain +
            "&access_token=" +
            accessToken +
            "&refresh_token=" +
            refreshToken
        );
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 403) {
          $cookies.set(
            "snedson_createdAccountGuid",
            error.response.data.accountGuid
          );
          $cookies.set(
            "snedson_createdAccountSchoolName",
            encodeURIComponent(error.response.data.schoolFullNameAndAdress)
          );
          state.signupFormData.isAccountVerified = false;
          state.isTherePostSignUpCookie = true;
        } else {
          state.loginFormData.errorText = error.response.data.messageToShow;
        }
      });
  };

  const signup = async () => {
    await axiosInstance({
      method: "post",
      url: process.env.VUE_APP_SNEDSON_AUTH_SERVER + "auth-api/signup/signup",
      data: {
        username: state.signupFormData.login,
        password: state.signupFormData.password,
        roleId: state.signupFormData.selectedRole + 1,
        schoolId: state.signupFormData.schoolIdFound,
        firstname: state.signupFormData.firstname,
        surname: state.signupFormData.surname,
        patronymic: state.signupFormData.patronymic,
        gender: state.signupFormData.selectedGender == 0 ? "Male" : "Female",
      },
    })
      .then((response) => {
        console.log(response);
        $cookies.set("snedson_createdAccountGuid", response.data.userGuid);
        $cookies.set(
          "snedson_createdAccountSchoolName",
          encodeURIComponent(response.data.schoolFullNameAndAdress)
        );
        state.signupFormData.isAccountVerified = false;
        state.isTherePostSignUpCookie = true;
      })
      .catch((response) => {
        console.log(response);
        state.loginFormData.errorText = response.data.messageToShow;
      });
  };

  const onSubmitSignupFirstStep = async () => {
    if (state.signupFormData.password != state.signupFormData.submitPassword) {
      state.signupFormData.errorText = "Пароли не совпадают. ";
      return;
    }

    if ((await checkNewAccount()) == true) {
      setSelectedForm(2);
    }
  };

  const backFromSecondStep = () => {
    setSelectedForm(1);
  };

  const backFromThirdStep = () => {
    setSelectedForm(2);
  };

  const onSubmitSignupSecondStep = () => {
    if (state.signupFormData.schoolId == "") {
      return;
    }

    setSelectedForm(3);
  };

  const checkAccountVerification = async () => {
    if ($cookies.isKey("snedson_createdAccountGuid")) {
      await axiosInstance({
        method: "get",
        url:
          process.env.VUE_APP_SNEDSON_AUTH_SERVER +
          "auth-api/signup/isaccountverified",
        params: {
          accountGuid: $cookies.get("snedson_createdAccountGuid"),
        },
      }).then((response) => {
        if (response.data.isVerified) {
          state.signupFormData.isAccountVerified = true;
        }
      });
    }
  };

  const onLeavePostSignupForm = () => {
    $cookies.remove("snedson_createdAccountGuid");
    $cookies.remove("snedson_createdAccountSchoolName");
    state.isTherePostSignUpCookie = false;
    setSelectedForm(0);
  };

  return {
    state,
    setSelectedForm,
    checkNewAccount,
    onSubmitSignupFirstStep,
    findSchoolById,
    onSubmitSignupSecondStep,
    login,
    signup,
    backFromSecondStep,
    backFromThirdStep,
    checkAccountVerification,
    onLeavePostSignupForm,
  };
}
