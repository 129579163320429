<template>
  <div class="home">
    <main-form class="main-form">
      <post-signup-form
        v-if="main.state.isTherePostSignUpCookie"
        :form="main.state.signupFormData"
        @checkVerificationStatues="main.checkAccountVerification()"
        @leavePostSignupForm="main.onLeavePostSignupForm()"
      />

      <login-form
        v-else-if="main.state.selected == 0"
        @submitLogin="main.login()"
        :form="main.state.loginFormData"
      />

      <signup-first-form
        v-else-if="main.state.selected == 1"
        @switchToAuthorization="main.setSelectedForm(0)"
        @submitFirstStep="main.onSubmitSignupFirstStep"
        :form="main.state.signupFormData"
      />

      <signup-second-form
        v-else-if="main.state.selected == 2"
        @fieldChanged="main.clearSignupErrors"
        @findSchool="main.findSchoolById"
        @backFromSecondStep="main.backFromSecondStep"
        @submitSecondStep="main.onSubmitSignupSecondStep"
        :form="main.state.signupFormData"
      />

      <signup-third-form
        v-else-if="main.state.selected == 3"
        @fieldChanged="main.clearSignupErrors"
        @backFromThirdStep="main.backFromThirdStep"
        @submitThridStep="main.signup"
        :form="main.state.signupFormData"
      />
    </main-form>
  </div>
</template>

<script setup>
import MainForm from "@/components/forms/MainForm.vue";
import LoginForm from "./Forms/Login/LoginForm.vue";
import SignupFirstForm from "./Forms/Signup/SignupFirstStepForm.vue";
import SignupSecondForm from "./Forms/Signup/SignupSecnondStepForm.vue";
import SignupThirdForm from "./Forms/Signup/SignupThirdStepForm.vue";
import PostSignupForm from "./Forms/Signup/PostSignUpForm.vue";
import { useMainPage } from "@/services/MainPage/useMainPage.js";
import { computed } from "vue";

const main = { ...useMainPage() };

main.state.isTherePostSignUpCookie = $cookies.isKey(
  "snedson_createdAccountGuid"
);
</script>

<style>
.main-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 650px) {
  .main-form {
    top: 0%;
    left: 0%;
    transform: none;
    width: 100%;
    height: 100%;
  }
}
</style>
