<template>
    <div class="signup-form-wrapper">
        <h1 class="signup-form-header">Создайте <span class="signup-form-header_your">ваш</span> <br>Snedson Account</h1>

        <form class="signup-form" @submit.prevent>
            <mcw-textfield 
                class="signup-form-login-input" 
                type="text" 
                v-model="form.login" 
                label="Ваш логин" 
                outline required fullwidth
                @change="form.errorText = ''" />

            <mcw-textfield 
                class="signup-form-password-input" 
                type="password" 
                v-model="form.password" 
                label="Придумайте пароль" 
                outline required fullwidth 
                @change="form.errorText = ''" />

            <mcw-textfield 
                class="signup-form-password-submit-input" 
                type="password" 
                v-model="form.submitPassword" 
                label="Повторите пароль" 
                outline required fullwidth 
                @change="form.errorText = ''" />

            <div class="signup-form-role__wrapper">
                <span class="signup-form-role-label">Ваша роль в школе</span>
                <mcw-segmented-button class="signup-form-role-selector" v-model="form.selectedRole" single-select>
                    <mcw-segment label="Ученик"></mcw-segment>
                    <mcw-segment label="Учитель"></mcw-segment>
                    <mcw-segment label="Администратор"></mcw-segment>
                </mcw-segmented-button>
            </div>

            <smdc-card 
                v-if="form.errorText != ''"
                background-color="#FFFFFF" 
                border-color="#B00020">
                    <p class="signup-form-errors">{{form.errorText}}</p>
            </smdc-card>

            <div class="signup-form-submit">
                <p class="signup-form-submit-switch-form">Есть аккаунт? <a @click="$emit('switchToAuthorization')">Авторизация </a> </p>
                
                <mcw-button 
                    @click="$emit('submitFirstStep')" 
                    class="signup-form-next-button"
                    raised>
                        Далее
                </mcw-button>
            </div>
        </form>

    </div>
</template>

<script setup>
import { onMounted, onBeforeMount, onActivated, reactive, toRefs } from 'vue';
import smdcCard from '@/components/materialComponents/smdcCard.vue';

const props = defineProps({
    form: Object,
})

const { form } = toRefs(props);

onMounted(() => {
    mdc.autoInit();
    mdc.textField.MDCTextField.attachTo(document.querySelector('.signup-form-login-input'));
    mdc.textField.MDCTextField.attachTo(document.querySelector('.signup-form-password-input'));
    mdc.textField.MDCTextField.attachTo(document.querySelector('.signup-form-password-submit-input'));
});

</script>


<style lang="scss">
.signup-form-login-input,
.signup-form-password-input,
.signup-form-password-submit-input,
.custom-outlined-button,
.mdc-floating-label,
.signup-form-next-button,
.customFloatLabel {
    --mdc-theme-primary: #F30007;
    --mdc-floating-label: #000000;
}


.signup-form-role{
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 100%;
    }

    &-label {
        font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
        font-size: calc(1rem * 0.75);
        letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
        text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
        text-align: left;
        //margin-left: 16px;
        color: #000000;
    }

    &-selector {
        --mdc-segmented-button-selected-ink-color: #F30007;
        --mdc-segmented-button-selected-container-fill-color: rgba(243, 0, 7, 0.08);
        --mdc-ripple-color: #F30007;
        width: 100%;
        display: flex !important;
        justify-content: space-between;
        margin-top: 5px;
    }
} 

.mdc-segmented-button__segment {
    flex-grow: 1;
}

.mdc-text-field__input, .mdc-segmented-button__ripple, .mdc-segmented-button__label {
    width: 100%;
}

.signup {
    &-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;
        //width: 350px;
        &-header {
            margin: 0;
            text-align: left;
            color: #000000;
            &_your {
                background-color: #F30007;
                color: #FFFFFF;
                border-radius: 5px;
                padding: 2px 10px;
            }
        }

        &-login-input, &-password-input, &-password-submit-input, .mdc-textfield, .mdc-text-field {
            width: 100%;
        }
    
        &-submit {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            //max-width: 350px;
            
            &-switch-form {
                word-break: break-all;
                overflow-wrap: break-word;
            }
        }

        &-errors {
            margin: 0;
            text-align: left;
            color: #F30007;
            word-break: break-word;
            overflow-wrap: break-word;
        }
    }

    &-redirect-info {
        &-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
        }

        &-icon {
            width: 60px;
            height: 60px;
            border-radius: 10px;
        }

        &-text {
            font-size: 17px;
        }
    }
}


.mdc-floating-label {
    -webkit-text-fill-color: black
}
</style>
