<template>
    <div class="signup-form-wrapper">
        
        <div class="signup-form-header_wrapper">
            <img @click="$emit('backFromThirdStep')" class="signup-form-header-icon" src="@/img/icons/arrow_back.svg"/>
            <h1 class="signup-form-header">Заполните данные о себе</h1>
        </div>

        <form class="signup-form" @submit.prevent>
            <mcw-textfield class="signup-form-firstname-input" type="text" v-model="form.firstname" label="Фамилия" outline required fullwidth />
            <mcw-textfield class="signup-form-surname-input" type="text" v-model="form.surname" label="Имя" outline required fullwidth />
            <mcw-textfield class="signup-form-patronymic-input" type="text" v-model="form.patronymic" label="Отчество" outline required fullwidth />

            <div class="signup-form-gender__wrapper">
                <span class="signup-form-gender-label">Пол</span>
                <mcw-segmented-button class="signup-form-gender-selector" v-model="form.selectedGender" single-select>
                    <mcw-segment label="Мужской"></mcw-segment>
                    <mcw-segment label="Женский"></mcw-segment>
                </mcw-segmented-button>
            </div>

            <smdc-card 
                v-if="form.errorText != ''"
                background-color="#FFFFFF" 
                border-color="#B00020">
                    <p class="signup-form-errors">{{form.errorText}}</p>
            </smdc-card>

            <mcw-button
                class="signup-form-submit-button"
                @click="$emit('submitThridStep')"
                raised> 
                    Регистрация
            </mcw-button>    
        </form>

    </div>
</template>

<script setup>
import { onMounted, onBeforeMount, onActivated, reactive, toRefs } from 'vue';
import smdcCard from '@/components/materialComponents/smdcCard.vue';

const props = defineProps({
    form: Object,
})

const { form } = toRefs(props);

onMounted(() => {
    mdc.autoInit();
});

</script>


<style lang="scss" scoped>
.signup-form-firstname-input,
.signup-form-surname-input,
.signup-form-patronymic-input,
.custom-outlined-button,
.mdc-floating-label,
.signup-form-submit-button,
.customFloatLabel {
    --mdc-theme-primary: #F30007;
    --mdc-floating-label: #000000;
    --mdc-outlined-button-outline-color: #F30007;
}

.signup-form-gender{
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 100%;
    }

    &-label {
        font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
        font-size: calc(1rem * 0.75);
        letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
        text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
        text-align: left;
        //margin-left: 16px;
        color: #000000;
    }

    &-selector {
        --mdc-segmented-button-selected-ink-color: #F30007;
        --mdc-segmented-button-selected-container-fill-color: rgba(243, 0, 7, 0.08);
        --mdc-ripple-color: #F30007;
        width: 100%;
        display: flex !important;
        justify-content: space-between;
        margin-top: 5px;
    }
} 

.mdc-text-field__input {
    width: 100%;
}

.signup {
    &-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;
        //width: 350px;
        &-header {
            &_wrapper {
                display: flex;
            }

            &-icon {
                width: 30px;
                height: 30px;
                cursor: pointer;
            }

            margin: 0;
            margin-left: 10px;
            font-size: 27px;
            text-align: left;
            color: #000000;
            &_your {
                background-color: #F30007;
                color: #FFFFFF;
                border-radius: 5px;
                padding: 2px 10px;
            }
        }

        &-field { 
            
            &_wrapper {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            &-info {
                text-align: left;
                font-size: 13px;
                color: #3F3F3F;
                margin: 0;
            }
        }

        &-signup-input, &-password-input, .mdc-textfield {
            width: 100%;
        }
    
        &-submit {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            
            &-found-text {
                word-break: break-word;
                overflow-wrap: break-word;
                text-align: left;
            }
        }

        &-find{
            display: flex;

            &-school-button {
                width: 100%;
            }
        }
    }

    &-redirect-info {
        &-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
        }
        &-icon {
            width: 60px;
            height: 60px;
            border-radius: 10px;
        }

        &-text {
            font-size: 17px;
        }
    }
}


.mdc-floating-label {
    -webkit-text-fill-color: black
}
</style>
