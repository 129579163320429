<template>
    <div class="signup-form-wrapper">
        
        <div class="signup-form-header_wrapper">
            <img @click="$emit('backFromSecondStep')" class="signup-form-header-icon" src="@/img/icons/arrow_back.svg"/>
            <h1 class="signup-form-header">Прикрепление к школе</h1>
        </div>

        <form class="signup-form" @submit.prevent>
            <div class="signup-form-field_wrapper">
                <mcw-textfield 
                    class="signup-form-login-input" 
                    type="text" 
                    v-model="form.schoolId" 
                    label="Уникальный номер школы" 
                    @change="onSchoolIdInputChanged()"
                    outline required fullwidth />
                <p class="signup-form-field-info">Этот уникальный номер вам должны сообщить в школе</p>
            </div>

            <smdc-card 
                v-if="form.errorText != ''"
                background-color="#FFFFFF" 
                border-color="#B00020">
                    <p class="signup-form-errors">{{form.errorText}}</p>
            </smdc-card>

            <div class="signup-form-find">
                <mcw-button
                    class="signup-form-find-school-button"
                    @click="$emit('findSchool')"
                    outlined> 
                        Найти школу
                </mcw-button>    
            </div>
            
            <div v-if="form.schoolNameFound != ''" class="signup-form-submit">
                <p class="signup-form-submit-found-text">Ваша школа - {{form.schoolNameFound}}?</p>
                <mcw-button
                    class="signup-form-next-button"
                    @click="$emit('submitSecondStep')"
                    raised> 
                        Далее
                </mcw-button>    
            </div>
        </form>

    </div>
</template>

<script setup>
import { onMounted, onBeforeMount, onActivated, reactive, toRefs } from 'vue';
import smdcCard from '@/components/materialComponents/smdcCard.vue';

const props = defineProps({
    form: Object,
})

const { form } = toRefs(props);

const onSchoolIdInputChanged = () => {
    form._object.form.errorText = ''
    form._object.form.schoolNameFound = ''
}

onMounted(() => {
    mdc.autoInit();
});

</script>


<style lang="scss" scoped>
.signup-form-login-input,
.signup-form-password-input,
.custom-outlined-button,
.mdc-floating-label,
.signup-form-find-school-button,
.customFloatLabel {
    --mdc-theme-primary: #F30007;
    --mdc-floating-label: #000000;
    --mdc-outlined-button-outline-color: #F30007;
}

.mdc-text-field__input {
    width: 100%;
}

.signup {
    &-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;
        //width: 350px;
        &-header {
            &_wrapper {
                display: flex;
            }

            &-icon {
                width: 30px;
                height: 30px;
                cursor: pointer;
            }

            margin: 0;
            margin-left: 10px;
            font-size: 27px;
            text-align: left;
            color: #000000;
            &_your {
                background-color: #F30007;
                color: #FFFFFF;
                border-radius: 5px;
                padding: 2px 10px;
            }
        }

        &-field { 
            
            &_wrapper {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            &-info {
                text-align: left;
                font-size: 13px;
                color: #3F3F3F;
                margin: 0;
            }
        }

        &-signup-input, &-password-input, .mdc-textfield {
            width: 100%;
        }
    
        &-submit {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            
            &-found-text {
                word-break: break-word;
                overflow-wrap: break-word;
                text-align: left;
            }
        }

        &-find{
            display: flex;

            &-school-button {
                width: 100%;
            }
        }
    }

    &-redirect-info {
        &-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
        }
        &-icon {
            width: 60px;
            height: 60px;
            border-radius: 10px;
        }

        &-text {
            font-size: 17px;
        }
    }
}


.mdc-floating-label {
    -webkit-text-fill-color: black
}
</style>
