<template>
    <div class="form">
        <div class="form-left">
            <img src="@/img/snedsonMainLogo.png" class="form-left-logo" />
        </div>
        <div class="form-right">
            <div class="form-content-wrapper">
                <slot />

            </div>
        </div>
    </div>
</template>
  
<script setup>

</script>

<style lang="scss">
.form {
    width: 550px;
    height: auto;
    min-height: 470px;
    border-radius: 10px;
    display: flex;
    align-items: stretch;
    &-content-wrapper {
        margin: 25px auto 25px 30px;
        width: 370px;
    }

    &-left {
        width: 134px;
        background: linear-gradient(4.92deg, #F30007 46.04%, #F310CE 100%);
        border-radius: 10px 0px 0px 10px;

        &-logo {
            width: 70px;
            height: 70px;
            margin-top: 35px;
        }
    }
    &-right {
        width: 100%;
        border-radius: 0px 10px 10px 0px;
        background: linear-gradient(70.77deg, #EFD7D9 25%, #F0D5EC 75%);
        display: flex;
        flex: 1 1 auto;
    }
}

@media (max-width: 650px) {
    .form {
        flex-direction: column;
        border-radius: 0px;

        &-left {
            width: 100%;
            height: 100px;
            min-height: 100px;
            display: flex;
            justify-content: left;
            align-items: center;
            border-radius: 0px;
            background: linear-gradient(45deg, #F30007 0%, #F310CE 100%);
            &-logo {
                width: 50px;
                height: 50px;
                margin-top: 0px;
                margin-left: 30px;
                margin-bottom: 10px;
            }
        }

        &-right {
            margin-top: -10px;
            height: auto;
            border-radius: 10px 10px 0px 0px;
        }

        &-content-wrapper {
            width: 100%;
            margin: 25px 30px 25px 30px;
        }
    }
}
</style>