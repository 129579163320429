<template>
    <div class="signup-form-wrapper">
        <div class="signup-form-header_wrapper">
            <h1 class="signup-form-header"> {{ !form.isAccountVerified ? "Ваш Snedson Account ожидает верификации" : "Ваш Snedson Account верфицирован!" }}</h1>
        </div>

        <div class="signup-form" v-if="!form.isAccountVerified">
            <p>Ваш аккаунт создан и теперь осталось дождаться, пока администрация школы подвердит, что вы действительно учитесь в данном образовательном учреждении. </p>
            <p>Ваши данные: </p>
            <div>
                <p><b>Уникальный ID:</b></p>
                <p>{{ $cookies.get('snedson_createdAccountGuid') }}</p>
            </div>
            <div>
                <p><b>Образовательное учреждение:</b></p>
                <p>{{ decodeURIComponent($cookies.get('snedson_createdAccountSchoolName')) }}</p>
            </div>
            <p>О том, что ваш аккаунт был подтвержден, вы узнаете на этой странице. Постарайтесь не забыть ваш логин и пароль. </p>
            <p>Переходите к авторизации только после того, как ваш аккаунт будет подтвержден. </p>

            <mcw-button 
                @click="$emit('leavePostSignupForm')" 
                class="signup-form-goto-auth"
                raised>
                    Перейти к авторизации
            </mcw-button>
        </div>

        <div class="signup-form" v-else>
            <p>Ваш аккаунт был успешно создан и подтвержден администрацией вашего образовательного учереждения!</p>
            <p>Желаем вам приятного использования сервисов Snedson</p>
            <mcw-button 
                @click="$emit('leavePostSignupForm')" 
                class="signup-form-goto-auth"
                raised>
                    Перейти к авторизации
            </mcw-button>
        </div>

    </div>
</template>

<script setup>
import { onMounted, onBeforeMount, onActivated, reactive, toRefs } from 'vue';

const emit = defineEmits(['checkVerificationStatues']);

const props = defineProps({
    form: Object,
})

const { form } = toRefs(props);

onMounted(() => {
    emit('checkVerificationStatues');
})

</script>

<style lang="scss" scoped>
.signup-form-goto-auth {
    --mdc-theme-primary: #F30007;
}

.signup {
    &-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 10px;
        //width: 350px;
        color: #000000;
        text-align: left;

        & h2 {
            margin: 0;
            color: #F30007;
        }

        &-header {
            &_wrapper {
                display: flex;
            }

            &-icon {
                width: 30px;
                height: 30px;
                cursor: pointer;
            }

            margin: 0;
            font-size: 27px;
            text-align: left;
            color: #000000;
            &_your {
                background-color: #F30007;
                color: #FFFFFF;
                border-radius: 5px;
                padding: 2px 10px;
            }
        }
    }
}

</style>