<template>
  <div class="login-form_wrapper">
    <h1 class="login-form-header">
      Войдите в <span class="login-form-header_your">ваш</span> <br />Snedson
      Account
    </h1>

    <form class="login-form" @submit.prevent>
      <mcw-textfield
        class="login-form-login-input"
        type="text"
        v-model="form.login"
        label="Ваш логин"
        @change="form.errorText = ''"
        outline
        required
        fullwidth
      />

      <mcw-textfield
        class="login-form-password-input"
        type="password"
        v-model="form.password"
        label="Ваш пароль"
        @change="form.errorText = ''"
        outline
        required
        fullwidth
      />

      <smdc-card
        v-if="form.errorText != ''"
        background-color="#FFFFFF"
        border-color="#B00020"
      >
        <p class="login-form-errors">{{ form.errorText }}</p>
      </smdc-card>

      <div class="login-form-submit">
        <p class="login-form-submit-switch-form">
          Нет аккаунта? <a>Регистрация</a>
        </p>

        <mcw-button
          class="login-form-submit-button"
          @click="$emit('submitLogin')"
          raised
        >
          Войти
        </mcw-button>
      </div>

      <div class="login-redirect-info_wrapper">
        <img class="login-redirect-info-icon" src="@/img/SnotraLogo.png" />
        <p class="login-redirect-info-text">
          После авторизации вы попадете на сайт Snotra вашей школы
        </p>
      </div>
      <smdc-card class="demo-account-data">
        <h2>Данные для входа в демонстрационные аккаунты:</h2>
        <h3><i>Нажмите, чтобы подставить их в форму</i></h3>
        <ul>
          <div @click="fillForm('SVasilieva', 'password')">
            <li><b>Аккаунт учителя (доступно в веб-версии): </b></li>
            <p>Логин: SVasilieva</p>
            <p>Пароль: password</p>
          </div>

          <div @click="fillForm('MKotowsky', 'password')">
            <li><b>Аккаунт ученика (доступно в мобильном приложении): </b></li>
            <p>Логин: MKotowsky</p>
            <p>Пароль: password</p>
          </div>
        </ul>
      </smdc-card>
    </form>
  </div>
</template>

<script setup>
import { onMounted, onBeforeMount, onActivated, reactive, toRefs } from "vue";
import smdcCard from "@/components/materialComponents/smdcCard.vue";

const props = defineProps({
  form: Object,
});

const { form } = toRefs(props);

onMounted(() => {
  mdc.autoInit();
  mdc.textField.MDCTextField.attachTo(
    document.querySelector(".login-form-login-input")
  );
  mdc.textField.MDCTextField.attachTo(
    document.querySelector(".login-form-password-input")
  );
});

const fillForm = (login, password) => {
  form._object.form.login = login;
  form._object.form.password = password;
};
</script>

<style lang="scss">
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  transition: background-color 5000s ease-in-out 0s;
}

.login-form-login-input,
.login-form-password-input,
.custom-outlined-button,
.mdc-floating-label,
.login-form-submit-button,
.customFloatLabel {
  --mdc-theme-primary: #f30007;
  --mdc-floating-label: #000000;
}

.mdc-text-field__input {
  width: 100%;
}

.login {
  &-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    //width: 350px;
    &-header {
      margin: 0;
      text-align: left;
      color: #000000;
      &_your {
        background-color: #f30007;
        color: #ffffff;
        border-radius: 5px;
        padding: 2px 10px;
      }
    }

    &-login-input,
    &-password-input,
    .mdc-textfield {
      width: 100%;
    }

    &-submit {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //max-width: 350px;

      &-switch-form {
        word-break: break-all;
        overflow-wrap: break-word;
        & a {
          cursor: pointer;
        }
      }
    }

    &-errors {
      margin: 0;
      text-align: left;
      color: #f30007;
      word-break: break-word;
      overflow-wrap: break-word;
    }
  }

  &-redirect-info {
    &_wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
    }
    &-icon {
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }

    &-text {
      font-size: 17px;
    }
  }
}

.mdc-floating-label {
  -webkit-text-fill-color: black;
}

.demo-account-data {
  text-align: left;
  & h2 {
    font-size: 20px;
    margin: 0 0 5px 0;
  }

  & h3 {
    font-size: 14px;
    margin: 0 0 10px 0;
  }

  & ul {
    list-style-image: url("@/assets/md_shapes_li-bullet_8x8.svg");
    padding-left: 20px;
    margin: 0;
    & div {
      cursor: pointer;
    }
  }
}
</style>
